import { combineReducers } from 'redux'

import averagePrices from './average_prices'
import unitsSold from './units_sold'
import seasonality from './seasonality'

export default combineReducers({
  averagePrices,
  unitsSold,
  seasonality
})
